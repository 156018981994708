<!--
 * @Author: 候怀烨
 * @Date: 2021-01-06 17:32:22
 * @LastEditTime: 2021-02-20 15:52:35
 * @LastEditors: Please set LastEditors
 * @Description: 调拨明细表
 * @FilePath: \sd-vue-admin\src\views\project\treasury\depotData\AllocationSchedule\index.vue
-->
<template>
  <!-- NAME[epic=库管] 调拨明细表 -->
  <div style="padding: 20px">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="out_id">
          <el-select
            v-model="form.out_id"
            :popper-class="'select-idx'"
            placeholder="请选择出货仓库"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in warehouse"
              :key="index"
              :label="item.depot_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="in_id">
          <el-select
            v-model="form.in_id"
            :popper-class="'select-idx'"
            placeholder="请选择入货仓库"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in warehouse"
              :key="index"
              :label="item.depot_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="class_id">
          <el-select
            v-model="form.class_id"
            :popper-class="'select-idx'"
            placeholder="请选择品类"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Category"
              :key="index"
              :label="item.class_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="brand_id">
          <brand-select
            ref="brandSelect"
            :duoxuan="true"
            @brand-select-change="brandChange"
          ></brand-select>
          <!-- <el-select
            v-model="form.brand_id"
            :popper-class="'select-idx'"
            placeholder="请选择品牌"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Brand"
              :key="index"
              :label="item.brand_name"
              :value="item.id"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item prop="keyword">
          <el-input
            v-model="form.keyword"
            placeholder="商品名称，简拼"
          ></el-input>
        </el-form-item>
        <el-form-item prop="allot_code">
          <el-input
            v-model="form.allot_code"
            placeholder="单号或客户名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="Time">
          <el-date-picker
            v-model="form.Time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="allot_status">
          <el-select
            v-model="form.allot_status"
            :popper-class="'select-idx'"
            placeholder="状态"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in status"
              :key="index"
              :label="item.status_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlersver">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <el-button @click="handlerExport">导 出</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        stripe
        :data="tableData"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column align="center" width="50">
          <!-- <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in colemd"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template> -->
          <template #default="{ row, $index }">
            <div v-if="row.allot_code.indexOf('合计') !== -1"></div>
            <div v-else>{{ $index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template #default="{ row }">
            <div
              v-if="list.label == '单号' && row[list.prop] != '本页合计'"
              class="underline"
              @click="handleOrderDetail(row)"
            >
              {{ row[list.prop] }}
            </div>
            <div v-else>{{ row[list.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <diaoboOrder ref="diaoboOrder"></diaoboOrder>
  </div>
</template>

<script>
  import { downloadFile, postAction } from '@/api/Employee'
  import brandSelect from '@/baseComponents/brandSelect'
  import diaoboOrder from '@/views/project/treasury/requisition/documents/components/AddGoods.vue'

  export default {
    components: { brandSelect, diaoboOrder },
    data() {
      return {
        form: {
          in_id: '', //出仓ID
          out_id: '', //入仓ID
          allot_code: '', //单号
          Time: [],
          start_time: '', //开始时间 如2020-10-1
          end_time: '', //结束时间 如2020-12-31
          query_time: '', //查询时间类型 0申请时间 1审核时间 默认是0
          allot_status: '', //调拨状态
          brand_id: '', //品牌ID
          class_id: '', //品类ID
          keyword: '', //产品关键字 简拼
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        warehouse: [],
        Category: [],
        Brand: [],

        status: [],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          Category: '/baseAdmin/common/goods-class-list',
          Brand: '/baseAdmin/common/brand-list',
          list: '/depotAdmin/bill-allot/detail-list',

          status: '/baseAdmin/common/allot-status',
        },
        tableData: [],
        checkList: [
          '单号',
          '出货仓库',
          '入货仓库',
          '申请日期',
          '审核时间',
          '商品编码',
          '商品名称',
          '单位换算',
          '申请数量',
          '实际入库',
          '单价',
          '金额',
          '备注',
        ],
        colemd: [
          {
            order: 1,
            label: '单号',
            align: 'center',
            prop: 'allot_code',
            width: '',
          },
          {
            order: 2,
            label: '出货仓库',
            align: 'center',
            prop: 'out_name',
            width: '',
          },
          {
            order: 3,
            label: '入货仓库',
            align: 'center',
            prop: 'in_name',
            width: '',
          },
          {
            order: 4,
            label: '申请日期',
            align: 'center',
            prop: 'add_time',
            width: '',
          },
          {
            order: 5,
            label: '审核时间',
            align: 'center',
            prop: 'check_time',
            width: '',
          },
          {
            order: 5.5,
            label: '商品编码',
            align: 'center',
            prop: 'goods_id',
            width: '',
          },
          {
            order: 6,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 7,
            label: '单位换算',
            align: 'center',
            prop: 'goods_convert',
            width: '',
          },
          {
            order: 8,
            label: '申请数量',
            align: 'center',
            prop: 'apply_goods_num',
            width: '',
          },
          {
            order: 9,
            label: '实际入库',
            align: 'center',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 9.4,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 9.5,
            label: '金额',
            align: 'center',
            prop: 'total_price',
            width: '',
          },
          {
            order: 10,
            label: '备注',
            align: 'center',
            prop: 'remark',
            width: '',
          },
        ],
        footer: {},
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.colemd.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      'form.Time'(val) {
        console.log(val)
        try {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } catch (error) {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    mounted() {
      this.handlerwarehouse()
      this.handlerCategory()
      this.handlerBrand()
      this.handlerlist()
      this.handlerstatus()
    },
    methods: {
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计'
            return
          }

          sums[9] = this.footer.apply_goods_num
          sums[10] = this.footer.goods_num

          // const values = data.map((item) => Number(item[column.property]))
          // if (!values.every((value) => isNaN(value))) {
          //   sums[index] = values.reduce((prev, curr) => {
          //     const value = Number(curr)
          //     if (!isNaN(value)) {
          //       return prev + curr
          //     } else {
          //       return prev
          //     }
          //   }, 0)
          //   sums[index] += ' 元'
          // } else {
          //   sums[index] = 'N/A'
          // }
        })

        return sums
      },
      //仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //品类
      handlerCategory() {
        postAction(this.url.Category, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品类')
            this.Category = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //品牌
      handlerBrand() {
        postAction(this.url.Brand, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品牌')
            this.Brand = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerstatus() {
        postAction(this.url.status, {})
          .then((res) => {
            console.log(res, '状态')
            this.status = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlersver() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerReset() {
        this.$refs.brandSelect.resetForm()
        this.$refs['form'].resetFields()
        this.handlerlist()
      },
      handlerlist() {
        var data = JSON.parse(JSON.stringify(this.form))
        delete data.Time
        postAction(this.url.list, data)
          .then((res) => {
            console.log(res)
            this.tableData = res.data.rows
            this.tableData.push({
              allot_code: '本页合计',
              apply_goods_num: res.data.footer_page.apply_goods_num,
              goods_num: res.data.footer_page.goods_num,
            })
            this.total = res.totalCount
            this.footer = res.data.footer
          })
          .catch((err) => {
            console.log(err)
          })
      },

      brandChange(val) {
        this.form.brand_id = val
      },
      handlerExport() {
        downloadFile(
          '/depotAdmin/bill-allot/export-detail-list',
          '调拨单明细表.xlsx',
          this.form
        )
      },
      handleOrderDetail(row) {
        this.$refs.diaoboOrder.dialogVisible = true
        this.$refs.diaoboOrder.type = 1
        this.$refs.diaoboOrder.handlerlist(row)
      },
    },
  }
</script>

<style></style>
